export enum DictionaryKey {
  Favourite = 'is_favourite',
  Liked = 'is_liked',
  Hated = 'is_hated',
  Neutral = 'is_neutral',
}

export interface DictionaryInterface {
  key: DictionaryKey;
  name: string;
  enum: number;
  icon: string;
}

type DictionaryList = {
  [key in DictionaryKey]: {
    id: number;
    value: number;
    name: string;
    icon: string;
  };
};

const dictionary: DictionaryInterface[] = [
  { key: DictionaryKey.Neutral, name: 'Без отношений', enum: -1, icon: 'smile-neutral' },
  { key: DictionaryKey.Hated, name: 'Нелюбимый', enum: 0, icon: 'smile-hated' },
  { key: DictionaryKey.Liked, name: 'Нравится', enum: 1, icon: 'smile-like' },
  { key: DictionaryKey.Favourite, name: 'Любимый', enum: 2, icon: 'smile-love' },
];

export function getDictionaryList(): DictionaryList {
  return Object.values(dictionary).reduce((list, item) => {
    list[item.key] = {
      id: item.enum,
      value: item.enum,
      name: item.name,
      icon: item.icon,
    };

    return list;
  }, {} as DictionaryList);
}
