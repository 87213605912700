import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "favorite-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.favorite?.icon)
      ? _withDirectives((_openBlock(), _createBlock(_component_icon_font, {
          key: 0,
          class: "favorite-item__icon",
          icon: _ctx.favorite.icon
        }, null, 8, ["icon"])), [
          [_directive_tooltip, _ctx.withTitle ? '' : _ctx.favorite.name]
        ])
      : _createCommentVNode("", true),
    (_ctx.withTitle)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.favorite?.name), 1)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}