
import './favoriteItem.scss';

import { defineComponent, PropType } from 'vue';

import IconFont from '@/components/icons/IconFont.vue';
import { getDictionaryList, DictionaryKey, DictionaryInterface } from '@/lib/Favourite';

const statusesDictionary = getDictionaryList();
export default defineComponent({
  name: 'FavoriteItem',
  components: { IconFont },
  props: {
    relation: {
      type: String as PropType<DictionaryKey>,
    },
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    favorite(): Partial<DictionaryInterface> | null {
      const { relation } = this;

      if (!relation || !(relation in statusesDictionary)) {
        return statusesDictionary.is_neutral;
      }

      return statusesDictionary[relation];
    },
  },
});
