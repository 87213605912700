<template>
  <div class="mr-editable-item__wrapper">
    <div>{{ data.name }}</div>

    <div>
      <span
        v-for="(action, index) in data.actions"
        :key="index"
        class="mr-admin-actions-icons"
        :class="index"
        @click="actionHandler(action, data.id)"
      >
        <font-awesome-icon v-if="index === 'delete'" :icon="['far', 'trash-alt']" />
      </span>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

library.add(faTrashAlt);
export default {
  props: ['data'],
  methods: {
    actionHandler(action, id) {
      this.$store.dispatch(action, { id: id, router: this.$router, item: this.data });
    },
  },
};
</script>
